import $ from 'lib/jquery.ui';
import _ from 'lib/lodash.custom';
import currency from 'app/views/helpers/currency';
import config from 'config/actionTagConfig';

var applePayInternalError = false;
var applePayVersion = 3;

// Since each apple pay merchant has to own its own domain (host), the NGPVAN-controlled domains are only used for testing
// Only show Apple Pay on NGPVAN-controlled domains if the tenant is in one of the testing states
// Technically looking for substrings, so it will include states like DNCZZ, DEMOM, etc.
var testStates = ['ZZ', 'VQA', 'EADEM', 'DEMO', 'EMAZZ'];
var ngpvanControlledDomains = ['secure.everyaction.com', 'secure.ngpvan.com', 'actions.everyaction.com', 'actions.ngpvan.com'];

// Our implementation of Apple Pay uses the Payment Request API, which is only available on certain browsers
// It's only supported on iOS 11.3+ and Safari 11.1+
// (See compatibility and details: https://developer.mozilla.org/en-US/docs/Web/API/Payment_Request_API)
function applePayEnabledOnBrowser(tenantUri) {
  return !!window.PaymentRequest && !!window.ApplePaySession
    && window.ApplePaySession.canMakePayments()
    && window.ApplePaySession.supportsVersion(applePayVersion)
    && (ngpvanControlledDomains.indexOf(location.host) === -1 || testStates.some(function (state) { return _.contains(tenantUri, state); }));
}

function validateAppleMerchant(event, controllerUrl, merchantIdentifier, title) {
  applePayInternalError = false;
  // Get apple merchant session
  $.ajax({
    type: 'POST',
    url: controllerUrl,
    dataType: 'json',
    async: false,
    data: {
      validationUrl: event.validationURL,
      merchantIdentifier: merchantIdentifier,
      displayName: title ? title.substring(0, 64) : title,
      host: location.host
    }
  }).done(function (sessionString) {
    event.complete(JSON.parse(sessionString));
  }).fail(function () {
    applePayInternalError = true;
    event.complete('fail');
  });
}

function buildApplePayFillDictionary(paymentResponse) {
  // Create Apple Pay Fill Dictionary
  var billingContact = paymentResponse.details.billingContact;
  var shippingContact = paymentResponse.details.shippingContact;

  var dict = {
    FirstName: billingContact.givenName,
    LastName: billingContact.familyName,
    AddressLine1: billingContact.addressLines && billingContact.addressLines[0],
    AddressLine2: billingContact.addressLines && billingContact.addressLines[1],
    City: billingContact.locality,
    StateProvince: billingContact.administrativeArea,
    PostalCode: billingContact.postalCode,
    Country: billingContact.countryCode,
    EmailAddress: shippingContact && shippingContact.emailAddress,
    HomePhone: shippingContact && shippingContact.phoneNumber,
    MobilePhone: shippingContact && shippingContact.phoneNumber
  };

  return dict;
}

function promptApplePay(merchantIdentifier, cards, amount, controllerUrl, title, requireEmail, requirePhone, displayName, displayItems) {
  try {
    // Create Apple Payment Body
    var applePayMethod = {
      supportedMethods: 'https://apple.com/apple-pay',
      data: {
        version: applePayVersion,
        merchantIdentifier: merchantIdentifier,
        merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
        supportedNetworks: cards, // luckily, our card abbreviations match apple's
        countryCode: 'US', // This is the country where the payment will be processed. For us, this is US for now.
        requiredBillingContactFields: ['postalAddress']
      },
    };

    // Create Payment Details Body
    var paymentDetails = {
      total: {
        label: displayName,
        amount: {
          value: amount,
          currency: currency.getCurrency().code
        },
      },
      displayItems: displayItems,
    };

    var paymentOptions = {
      requestPayerEmail: requireEmail,
      requestPayerPhone: requirePhone
    };

    // Create Payment Request
    // PaymentRequest API is only compatible with certain browsers/browser versions
    // See description of isApplePayEnabled for more information
    var paymentRequest = new window.PaymentRequest([applePayMethod], paymentDetails, paymentOptions);

    // Set Merchant Validator
    paymentRequest.onmerchantvalidation = function (ev) {
      validateAppleMerchant(ev, controllerUrl, merchantIdentifier, title);
    };

    // Show apple payment sheet and handle response
    return paymentRequest.show()
      .then(function (paymentResponse) {
        var dict = buildApplePayFillDictionary(paymentResponse);

        // Complete payment request
        paymentResponse.complete('success');

        return {
          paymentResponseDetails: paymentResponse.details,
          expires: Date.now() + 60000 * config.APPLE_PAY_SESSION_TIMEOUT_MINUTES,
          fill_dict: dict
        };
      })
      .catch(function () {
        return {
          applePayInternalError: applePayInternalError
        };
      });
  }
  catch (e) {
    var failureDeferred = $.Deferred();
    failureDeferred.resolve({
      applePayInternalError: true
    });
    return failureDeferred;
  }
}

export default {
  enabledOnBrowser: applePayEnabledOnBrowser,
  promptApplePay: promptApplePay
};
