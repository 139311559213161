//This file is generated via grunt build and should not be modified directly
/* jshint ignore:start */
export default {
  "VGS_VAULT_ID": "tntd7qfd65p",
  "VGS_JS_URL": "https://js.verygoodvault.com/vgs-collect/2.18.4/vgs-collect.js",
  "ASSET_BASE_URL": "//s3.amazonaws.com/actiontag-staging/actiontag/staging/feature/stripejs-apple-google-pay/assets",
  "ZIP_LOOKUP_BASE_URL": "//d3rse9xjbp8270.cloudfront.net/assets/zip",
  "VGS_ENVIRONMENT": "sandbox"
};
/* jshint ignore:end */
